import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./nexus-mentis.module.scss"
import screenshot from "../../assets/images/projects/nexus-mentis-screenshot.png"
import resultsImg from "../../assets/images/projects/nexus-mentis-results.png"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const challengeItems = [
  "projects.nexus-mentis.challenge.paragraph-1-list.item-1",
  "projects.nexus-mentis.challenge.paragraph-1-list.item-2",
  "projects.nexus-mentis.challenge.paragraph-1-list.item-3",
]

const cubeItems = [
  "projects.nexus-mentis.solution-description.paragraph-3-list.item-2-list.item-1",
  "projects.nexus-mentis.solution-description.paragraph-3-list.item-2-list.item-2",
  "projects.nexus-mentis.solution-description.paragraph-3-list.item-2-list.item-3",
  "projects.nexus-mentis.solution-description.paragraph-3-list.item-2-list.item-4",
]

const resultsItems = [
  "projects.nexus-mentis.results.paragraph-2-list.item-2",
  "projects.nexus-mentis.results.paragraph-2-list.item-3",
  "projects.nexus-mentis.results.paragraph-2-list.item-4",
  "projects.nexus-mentis.results.paragraph-2-list.item-5",
]

const NexusMentis: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const aboutClasses = classNames(
    componentStyles.about,
    "container"
  )
  const aboutTitleClasses = classNames(
    componentStyles.aboutTitle,
    "large-section-title"
  )
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
  )
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const challengeClasses = classNames(
    componentStyles.challenge,
    "container"
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title"
  )
  const numberedListClasses = classNames(
    componentStyles.solutionDescriptionNumberedList,
    "grid-3"
  )
  const projectsTitleClasses = classNames("large-section-title")
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container"
  )
  const solutionDescriptionTickListClasses = classNames(
    componentStyles.solutionDescriptionList,
    "grid-2"
  )
  const solutionDescriptionTickListItemClasses = classNames(
    componentStyles.solutionDescriptionListItem,
    componentStyles.tickListItem,
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title"
  )
  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundContainerClasses = classNames(
    componentStyles.technologyBackgroundContainer,
    "grid-2"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
  )
  const technologyBackgroundTextListClasses = classNames(
    componentStyles.technologyBackgroundTextList,
  )
  const resultsClasses = classNames(componentStyles.results, "container")
  const resultContainerClasses = classNames(
    componentStyles.resultsContainer,
  )
  const resultsListClasses = classNames(
    componentStyles.resultsList,
  )
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title"
  )

  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  const breakpoints = useBreakpoint()

  const lastIndex = cubeItems.length - 1

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.nexus-mentis.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.nexus-mentis.seo.title" })}
        pathname={location.pathname}
      />
      <section className={componentStyles.banner} dir="ltr">
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.nexus-mentis.banner.title",
              })}
            </h1>
          </div>
        </div>
      </section>
      <section className={aboutClasses} dir="ltr">
        <div className={componentStyles.aboutTextBlock}>
          <h2 className={aboutTitleClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.about.title",
            })}
          </h2>
          <p className={componentStyles.aboutText}>
            <a href="https://about.nexusmentis.com/#home" className={componentStyles.link} target="_blank">
              {intl.formatMessage({
                id: "projects.nexus-mentis.about.paragraph-1.link",
              })}
            </a>
            {intl.formatMessage({
              id: "projects.nexus-mentis.about.paragraph-1.text",
            })}
          </p>
          <p className={componentStyles.aboutText}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.about.paragraph-2",
            })}
          </p>
        </div>
      </section>
      <section className={challengeClasses} dir="ltr">
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={challengeTitleClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.challenge.title",
            })}
          </h2>
          <p className={componentStyles.challengeText}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.challenge.paragraph-1",
            })}
          </p>
        </div>
        <div className={componentStyles.challengeNumberedListWrapper}>
          <ol className={numberedListClasses}>
            {challengeItems.map(translatePath => (
              <li key={translatePath} className={componentStyles.solutionDescriptionNumberedListItem}>
                <p className={componentStyles.solutionDescriptionNumberedListItemText}>
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </p>
              </li>
            ))}
          </ol>
        </div>
      </section>
      <section className={solutionDescriptionClasses} dir="ltr">
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-1",
            })}
          </p>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-2",
            })}
          </p>
          <h3 className={componentStyles.solutionDescriptionListTitle}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-title",
            })}
          </h3>
        </div>
        <ul className={solutionDescriptionTickListClasses}>
          <li className={solutionDescriptionTickListItemClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-list.item-1",
            })}
          </li>
          <li className={solutionDescriptionTickListItemClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-list.item-2",
            })}
            <ul className={componentStyles.solutionDescriptionCubeList}>
              {cubeItems.map((translatePath, index) => (
                <li key={translatePath} className={componentStyles.solutionDescriptionCubeListItem}>
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                  {index === lastIndex && (breakpoints.mediaBefore600 && ';' || '.')}
                </li>
              ))}
            </ul>
          </li>
          <li className={solutionDescriptionTickListItemClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-list.item-3",
            })}
          </li>
          <li className={solutionDescriptionTickListItemClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-list.item-4",
            })}
          </li>
          <li className={solutionDescriptionTickListItemClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-list.item-5",
            })}
          </li>
          <li className={solutionDescriptionTickListItemClasses}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.paragraph-3-list.item-6",
            })}
          </li>
          <li className={componentStyles.solutionDescriptionListItemImage}>
            <img
              src={screenshot}
              className={componentStyles.solutionDescriptionImage}
              alt={intl.formatMessage({
                id: "projects.nexus-mentis.solution-description.image-alt",
              })}
              width="452"
              height="335"
            />
          </li>
        </ul>
      </section>
      <section className={technologyBackgroundClasses} dir="ltr">
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
            id: "projects.nexus-mentis.technology-background.title",
          })}
        </h2>
        <div className={technologyBackgroundContainerClasses}>
          <p className={componentStyles.technologyBackgroundForm}>
            {intl.formatMessage({
              id: "projects.nexus-mentis.technology-background.paragraph-1",
            })}
          </p>
          <div className={componentStyles.technologyBackgroundListContainer}>
            <h3 className={componentStyles.technologyBackgroundListContainerTitle}>
              {intl.formatMessage({
                id: "projects.nexus-mentis.technology-background.paragraph-2-title",
              })}
            </h3>
            <ul className={technologyBackgroundTextListClasses}>
              <li className={componentStyles.technologyBackgroundListItem}>
                <p className={componentStyles.technologyBackgroundListItemTitle}>
                  {intl.formatMessage({
                    id: "projects.nexus-mentis.technology-background.paragraph-3-list.item-1-title",
                  })}
                </p>
                <p className={componentStyles.technologyBackgroundListItemText}>
                  {intl.formatMessage({
                    id: "projects.nexus-mentis.technology-background.paragraph-3-list.item-1-text",
                  })}
                </p>
              </li>
              <li className={componentStyles.technologyBackgroundListItem}>
                <p className={componentStyles.technologyBackgroundListItemTitle}>
                  {intl.formatMessage({
                    id: "projects.nexus-mentis.technology-background.paragraph-3-list.item-2-title",
                  })}
                </p>
                <p className={componentStyles.technologyBackgroundListItemText}>
                  {intl.formatMessage({
                    id: "projects.nexus-mentis.technology-background.paragraph-3-list.item-2-text",
                  })}
                </p>
              </li>
              <li className={componentStyles.technologyBackgroundListItem}>
                <p className={componentStyles.technologyBackgroundListItemTitle}>
                  {intl.formatMessage({
                    id: "projects.nexus-mentis.technology-background.paragraph-3-list.item-3-title",
                  })}
                </p>
                <p className={componentStyles.technologyBackgroundListItemText}>
                  {intl.formatMessage({
                    id: "projects.nexus-mentis.technology-background.paragraph-3-list.item-3-text",
                  })}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className={resultsClasses} dir="ltr">
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.nexus-mentis.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.nexus-mentis.results.paragraph-1",
          })}
        </p>
        <div className={resultContainerClasses}>
          <ul className={resultsListClasses}>
            <p className={componentStyles.resultsText}>
              {intl.formatMessage({
                id: "projects.nexus-mentis.results.paragraph-2",
              })}
            </p>
            {resultsItems.map(translatePath => (
              <li key={translatePath} className={componentStyles.resultsListItem}>
                {intl.formatMessage({
                  id: translatePath,
                })}
              </li>
            ))}
          </ul>
          <img
            src={resultsImg}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.nexus-mentis.solution-description.image-alt",
            })}
            width="452"
            height="335"
          />
        </div>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link, textColor }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                textColor={textColor}
                buttonColor="orange"
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout>
  )
}
export default NexusMentis
